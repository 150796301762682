import { FormLogin } from "./login-form";
import styles from "./hero.module.css";

export function Hero() {
  return (
    <section id="inicio" className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.info}>
            <p>
              <b>Aproveite condições especiais</b> e feche seu acordo agora até
            </p>
            <p>
              <span>95% </span>
              desconto
            </p>
          </div>

          <img src="/images/banner/stripe_0225.webp" alt="" />
          <img src="/images/banner/stripe_0225.webp" alt="" />
          <img src="/images/banner/stripe_0225.webp" alt="" />
          <img src="/images/banner/stripe_0225.webp" alt="" />
          <img src="/images/banner/liberty.webp" alt="" />
        </div>

        <FormLogin />
      </div>
    </section>
  );
}
